import { Component, ViewChild, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatTable } from '@angular/material';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.css']
})
export class TopicComponent implements OnInit  {
  displayedColumns: string[] = ['name', 'content' , 'action'];
  dataSource: any;

  @ViewChild(MatPaginator,{ static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  public topics: Topic[];
  public topic: Topic;
 
  constructor(public datepipe: DatePipe, public dialog: MatDialog, public http: HttpClient, @Inject('BASE_URL') public baseUrl: string) {
   
  }


  openDialog(action, obj) {
    obj.action = action;
   
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '600px',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        this.addRowData(result.data);
      } else if (result.event == 'Update') {
        this.updateRowData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  addRowData(newRow) {
    this.dataSource.data.push({
      name: newRow.name,
      content: newRow.content,
      id: 0
    });
    this.dataSource._updateChangeSubscription();
    this.topic = {
      name: newRow.name,
      content: newRow.content,
      id: 0
    };
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(this.topic);
    this.http.post<Topic>(this.baseUrl + 'api/Topics', body, { 'headers': headers }).subscribe(data => {
    })
      this.table.renderRows();
  }
  updateRowData(newRow) {
    this.dataSource.data = this.dataSource.data.filter((value, key) => {
      if (value.id == newRow.id) {
        value.name = newRow.name;
        value.content = newRow.content
      }

      this.topic = {
        name: newRow.name,
        content: newRow.content,
        id: newRow.id
      };
      const headers = { 'content-type': 'application/json' }
      const body = JSON.stringify(this.topic);
      this.http.put<Topic[]>(this.baseUrl + 'api/Topics/' + newRow.id, body, { 'headers': headers }).subscribe(data => {
      })
      return true;
    });
  }
  deleteRowData(newRow) {
    this.dataSource.data = this.dataSource.data.filter((value, key) => {
      this.http.delete<any>(this.baseUrl + 'api/Topics/' + newRow.id).subscribe(data => {
      })
      return value.id != newRow.id;
    });
  }



  ngOnInit() {
    this.http.get<Topic[]>(this.baseUrl + 'api/topics').subscribe(result => {
      this.dataSource = new MatTableDataSource<Topic>();
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
    }, error => console.error(error));
  }

}
export interface Topic {
  content: string;
  name: string;
  id: number;
}

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatTable, MatTableDataSource } from '@angular/material';
import { AddViolationData, AddViolationDialogComponent } from './add-violation-dialog/add-violation-dialog.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_DATE_FORMATS } from '../topic/dialog-box/dialog-box.component';
@Component({
  selector: 'app-violation',
  templateUrl: './violation.component.html',
  styleUrls: ['./violation.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class ViolationComponent implements OnInit {
  displayedColumns = ['checked', 'ticketNo', 'state', 'licensePlate'];
  dataSource: any;
  constructor(public http: HttpClient, @Inject('BASE_URL') public baseUrl: string, private changeDetectorRefs: ChangeDetectorRef, public dialog: MatDialog) { }
  public violations: Violation[];
  showGrid: boolean = false;
  showViolationMsg: boolean = false;
  showViolationMsgText: string = '';
  public selectedValue: string;
   startDate: string;
   endDate: string;
  public selectedTenant: string;
  error: any = { isError: false, errorMessage: '' };
  isValidDate: boolean = true;
  public noticeList: DropdownModel[] = [
    { text: 'NIC', value: 'NIC' },
    { text: 'CRC Eligible', value: 'CRC' },
    { text: 'Convict CRC Ticket', value: 'CFR' },
    { text: 'Notice of Trial', value: 'NOT' },
    { text: 'NFDD', value: 'NFDD' },
    { text: 'N.F.D.D After N.O.T', value: 'NFDDT' },
     { text: 'CRPD', value: 'CRPD' },
    { text: 'COLLECTIONS', value: 'COLLECTIONS' }
  ];
  public tenantList: DropdownModel[];
  
  public noticeDetails: NoticeDetails;
  public violationDetails: ViolationDetails;
  public addViolationData: AddViolationData;
  public Ids: string;
  public violation: Violation;
  public noticeForm: FormGroup;
  public tenantForm: FormGroup;
  showMsg: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  ngOnInit() {
    this.hideloader();

    this.dataSource = new MatTableDataSource<Violation>();
    this.dataSource.paginator = this.paginator;

    this.tenantForm = new FormGroup({
      tenantName: new FormControl('', [Validators.required]),
    });
    this.noticeForm = new FormGroup({
      notice: new FormControl('', [Validators.required]),
      startDate: new FormControl(),
      endDate: new FormControl()
    });
    this.Ids = "";

    this.addViolationData = <AddViolationData>({
      selectedState: '',
      action: ''
    });
    this.http.get<DropdownModel[]>(this.baseUrl + 'api/Parking/GetTenants').subscribe(result => {
      this.tenantList = result;
    }, error => console.error(error));

   

  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  public checkError = (controlName: string, errorName: string) => {
    return this.noticeForm.controls[controlName].hasError(errorName);
  }
  public checkTenantError = (controlName: string, errorName: string) => {
    return this.tenantForm.controls[controlName].hasError(errorName);
  }
  highlight(element: Violation) {
    element.highlighted = !element.highlighted;
    if (element.highlighted) {
      if (this.Ids.length == 0) {
        this.Ids = element.id.toString();
      }
      else {
        this.Ids = this.Ids + "," + element.id.toString();
      }
    }
    else {
      this.Ids = this.Ids.replace(element.id.toString() + ',', '');
    }
  }
  hideloader() {
    const spinner = Array.from(document.getElementsByClassName('spinner-border') as HTMLCollectionOf<HTMLElement>)
    spinner.forEach((element) => {
      element.style.display = 'none';
    });
  }
  openDialog(action, obj) {
    obj.action = action;
   
    const dialogRef = this.dialog.open(AddViolationDialogComponent, {
      width: '600px',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.action == 'Add') {
        this.addViolation(result.data);
      }
    });
  }
  showLoader() {
    const spinner = Array.from(document.getElementsByClassName('spinner-border') as HTMLCollectionOf<HTMLElement>)
    spinner.forEach((element) => {
      element.style.display = 'block';
    });
  }
  migratetoSelectedStage() {

    if (this.validateDates(this.startDate, this.endDate) && this.startDate !=null && this.endDate!=null) {
      this.noticeDetails = <NoticeDetails>({
        SelectedInfractions: this.Ids,
        Stage: this.selectedValue,
        TenantName: this.selectedTenant,
        StartDate: this.startDate,
        EndDate: this.endDate
      });
    }
    else if (this.Ids.length > 0 && this.selectedValue.length > 0 && this.isValidDate) {
      this.noticeDetails = <NoticeDetails>({
        SelectedInfractions: this.Ids,
        Stage: this.selectedValue,
        TenantName: this.selectedTenant,
        StartDate: '',
        EndDate: ''
      });

      this.Ids.split(',').forEach(item => {
        let index: number = this.dataSource.data.findIndex(d => d.id === parseInt(item));
        this.dataSource.data.splice(index, 1)
        this.dataSource = new MatTableDataSource<Element>(this.dataSource.data);
        this.dataSource.paginator = this.paginator;
      });
      this.Ids = "";
    }
    else if (this.isValidDate === false) {
      return;
    }


    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(this.noticeDetails);

    this.http.post<NoticeDetails>(this.baseUrl + 'api/Parking', body, { 'headers': headers }).subscribe(data => {
      this.showMsg = true;
    })

  }
  markAsEligible() {

    if (this.validateDates(this.startDate, this.endDate) && this.startDate != null && this.endDate != null) {
      this.noticeDetails = <NoticeDetails>({
        SelectedInfractions: this.Ids,
        Stage: this.selectedValue,
        TenantName: this.selectedTenant,
        StartDate: this.startDate,
        EndDate: this.endDate
      });
    }
    else if (this.Ids.length > 0 && this.selectedValue.length > 0 && this.isValidDate) {
      this.noticeDetails = <NoticeDetails>({
        SelectedInfractions: this.Ids,
        Stage: this.selectedValue,
        TenantName: this.selectedTenant,
        StartDate: '',
        EndDate: ''
      });

      this.Ids.split(',').forEach(item => {
        let index: number = this.dataSource.data.findIndex(d => d.id === parseInt(item));
        this.dataSource.data.splice(index, 1)
        this.dataSource = new MatTableDataSource<Element>(this.dataSource.data);
        this.dataSource.paginator = this.paginator;
      });
      this.Ids = "";
    }
    else if (this.isValidDate === false) {
      return;
    }


    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(this.noticeDetails);

    this.http.post<NoticeDetails>(this.baseUrl + 'api/Parking/MarkAsEligible', body, { 'headers': headers }).subscribe(data => {
      this.showMsg = true;
    })

  }
  tenantSelectChangeEvent(event) {
    this.showGrid = false;
  }
  bindGrid() {
    this.showLoader();
    this.http.get<Violation[]>(this.baseUrl + 'api/Parking?tenantName=' + this.selectedTenant + '').subscribe(result => {
      this.dataSource = new MatTableDataSource<Violation>();
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
      this.showGrid = true;
      this.hideloader();
    }, error => console.error(error));
  }
  addViolation(data) {

    this.violationDetails = <ViolationDetails>({
      SelectedStates: data.selectedState,
      TenantName: this.selectedTenant
    });
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(this.violationDetails);
   
    this.http.post<ApiResult>(this.baseUrl + 'api/Parking/AddNew', body, { 'headers': headers }).subscribe(data => {
      this.showViolationMsg = true;
      this.showViolationMsgText = "Violation #" + data.message + " Created Successfully";
      this.bindGrid()
    })
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {
      this.error = { isError: true, errorMessage: 'End date should be grater then start date.' };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

}
export interface Violation {
  checked: boolean;
  id: number;
  ticketNo: string;
  state: string;
  licensePlate: string;
  highlighted: boolean;
}
export interface NoticeDetails {
  SelectedInfractions: string;
  Stage: string;
  TenantName: string;
  StartDate: string;
  EndDate: string;
}
export interface ViolationDetails {
  SelectedStates: string;
  noOfSelectedTickets: number;
  ticketCSVString: string;
  TenantName: string;
}
export interface DropdownModel {
  text: string;
  value: string;
}
export interface ApiResult {
  Status: string;
  message: string;
}

import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_LOCALE } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownModel } from '../../nav-menu/nav-menu.component';

export interface PaymentData {
  selectedpaymentSource: string;
  selectedcitationNo: string;
  enteredamt: number;
  TenantName: string;
  action: string;
}

@Component({
  selector: 'app-payment-dialouge',
  templateUrl: './payment-dialouge.component.html',
  styleUrls: ['./payment-dialouge.component.css']
})
export class PaymentDialougeComponent  {

  public addPaymentFormGroup: FormGroup;
  action: string;
  local_data: any;
  constructor(
    public dialogRef: MatDialogRef<PaymentDialougeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PaymentData) {
    console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  public paymentSources: DropdownModel[] = [
    { text: 'Mto', value: '1' },
    { text: 'Non-Mto', value: '2' }
  ];


  ngOnInit(): void {

    this.addPaymentFormGroup = new FormGroup({
      paymentSource: new FormControl('', [Validators.required]),
      amt: new FormControl('', [Validators.required]),
      citationNo: new FormControl('', [Validators.required])
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.addPaymentFormGroup.controls[controlName].hasError(errorName);
  }
  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  closeDialog() {
    this.local_data.action = 'Cancel';
    this.dialogRef.close({ event: 'Cancel', data: this.local_data });
  }

}

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { EventEmitterService } from '../event-emitter.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  public loginDetails: LoginDetails;
  public loginInvalid = false;
  private formSubmitAttempt = false;
  private returnUrl: string;
  showMsg: boolean = false;
  constructor(
    private fb: FormBuilder,
    @Inject('BASE_URL') public baseUrl: string,
    public http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private eventEmitterService: EventEmitterService
  ) {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  async ngOnInit(): Promise<void> {
    if (localStorage.getItem("IsUserAuthenticated")) {
      await this.router.navigate([this.returnUrl]);
    }
  }
  async onSubmit(): Promise<void> {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    if (this.form.valid) {
      try {
        const username = this.form.get('username').value;
        const password = this.form.get('password').value;

        this.loginDetails = <LoginDetails>({
          Username: username,
          Password: password
        });

        const headers = { 'content-type': 'application/json' }
        const body = JSON.stringify(this.loginDetails);

        this.http.post<LoginResult>(this.baseUrl + 'api/Login/Authorize', body, { 'headers': headers }).subscribe(data => {
          if (data.status) {
            localStorage.setItem("IsUserAuthenticated", "true");
            /*this.eventEmitterService.onLogInSuccess();*/
            /*this.router.navigate(['/stages']);*/
           window.location.href='/stages';
          }
          else {
            this.showMsg = true;
          }
        })
      } catch (err) {
        this.loginInvalid = true;
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }

}
export interface LoginDetails {
  Username: string;
  Password: string;
}
export interface LoginResult {
  status: boolean;
  Message: string;
}

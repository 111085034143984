import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopicComponent } from './topic/topic.component';
import { DialogBoxComponent } from './topic/dialog-box/dialog-box.component';
import { DatePipe } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MomentDateModule } from '@angular/material-moment-adapter';
import {
  MatTableModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
  MatCheckboxModule,
  MatSelectModule,
  MatCardModule,
  MatRadioModule
} from '@angular/material';
import { ViolationComponent } from './violation/violation.component';
import { AddViolationDialogComponent } from './violation/add-violation-dialog/add-violation-dialog.component';
import { MovenoticeComponent } from './notice/movenotice.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';
import { PaymentDialougeComponent } from './payments/payment-dialouge/payment-dialouge.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    TopicComponent,
    DialogBoxComponent,
    ViolationComponent,
    AddViolationDialogComponent,
    MovenoticeComponent,
    LoginComponent,
    PaymentDialougeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatInputModule,
    MatPaginatorModule,
    MomentDateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatCardModule,
    MatIconModule,
    MatRadioModule,
    RouterModule.forRoot([
      /* { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuardService] },*/
      { path: '', component: MovenoticeComponent, canActivate: [AuthGuardService] },
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
      { path: 'topics', component: TopicComponent, canActivate: [AuthGuardService]},
      { path: 'violations', component: ViolationComponent, canActivate: [AuthGuardService] },
      { path: 'stages', component: MovenoticeComponent, canActivate: [AuthGuardService]},
      { path: 'login', component: LoginComponent }
    ])
  ],
  entryComponents: [
    DialogBoxComponent,
    AddViolationDialogComponent,
    PaymentDialougeComponent
  ],
  providers: [
    MatDatepickerModule,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
    
@Injectable({    
  providedIn: 'root'    
})    
export class EventEmitterService {    
    
  broadCastSelectedTenant = new EventEmitter();
  broadCastLogInSuccess = new EventEmitter();
  broadCastLogOut = new EventEmitter();
  subsTenantVar: Subscription;
  subsLoginVar: Subscription;
  subsLogOutVar: Subscription;
    
  constructor() { }    
    
  onTenantSelectedChangeEvent(selectedTenant:string) {    
    this.broadCastSelectedTenant.emit(selectedTenant);
  }
  onLogInSuccess() {
    this.broadCastLogInSuccess.emit();
  }
  onLogOut() {
    this.broadCastLogOut.emit();
  }
}  

import { ApiResult, DropdownModel, NoticeDetails, Violation, ViolationDetails } from '../violation/violation.component';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatTable, MatTableDataSource } from '@angular/material';
import { EventEmitterService } from '../event-emitter.service';
import { OnDestroy } from '@angular/core';
import { AddViolationData, AddViolationDialogComponent } from '../violation/add-violation-dialog/add-violation-dialog.component';
import { PaymentData } from '../payments/payment-dialouge/payment-dialouge.component';
import { PaymentDialougeComponent } from '../payments/payment-dialouge/payment-dialouge.component';
@Component({
  selector: 'app-movenotice',
  templateUrl: './movenotice.component.html',
  styleUrls: ['./movenotice.component.css'],
})
export class MovenoticeComponent implements OnInit, OnDestroy   {


  displayedColumns = ['checked', 'ticketNo', 'state', 'licensePlate'];
  dataSource: any;
  constructor(public http: HttpClient, @Inject('BASE_URL') public baseUrl: string, private changeDetectorRefs: ChangeDetectorRef, public dialog: MatDialog, private eventEmitterService: EventEmitterService    ) {
   
  }
  public violationDetails: ViolationDetails;
  public violations: Violation[];
  showGrid: boolean = false;
  showViolationMsg: boolean = false;
  selectedTenant: string;
  showViolationMsgText: string = '';
  public selectedValue: string;
  public addViolationData: AddViolationData;
  public addPaymentData: PaymentData;
  startDate: string;
  endDate: string;
  error: any = { isError: false, errorMessage: '' };
  isValidDate: boolean = true;
  public noticeList: DropdownModel[] = [
    { text: 'NIC', value: 'NIC' },
    { text: 'CRC Eligible', value: 'CRC' },
    { text: 'CRPD', value: 'CRPD' },
    { text: 'COLLECTIONS', value: 'COLLECTIONS' }
  ];

  public noticeDetails: NoticeDetails;

  public Ids: string;
  public violation: Violation;
  public noticeForm: FormGroup;
  public tenantForm: FormGroup;
  showMsg: boolean = false;
  showNoticeDrp: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;


  ngOnInit() {

    if (this.eventEmitterService.subsTenantVar == undefined) {
      this.eventEmitterService.subsTenantVar = this.eventEmitterService.
        broadCastSelectedTenant.subscribe((name: string) => {
          this.tenantSelectChangeEvent(name);
        });
    }

    this.addViolationData = <AddViolationData>({
      selectedState: '',
      selectedTenant:'',
      action: ''
    });

    this.addPaymentData = <PaymentData>({
      enteredamt: 0,
      selectedcitationNo: '',
      selectedpaymentSource: '',
      TenantName: '',
      action: ''
    });

    this.hideloader();

    this.dataSource = new MatTableDataSource<Violation>();
    this.dataSource.paginator = this.paginator;

    this.tenantForm = new FormGroup({
      tenantName: new FormControl('', [Validators.required]),
      notice: new FormControl('', [Validators.required]),
    });
    this.Ids = "";
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  public checkTenantError = (controlName: string, errorName: string) => {
    return this.tenantForm.controls[controlName].hasError(errorName);
  }
  highlight(element: Violation) {
    element.highlighted = !element.highlighted;
    if (element.highlighted) {
      if (this.Ids.length == 0) {
        this.Ids = element.id.toString();
      }
      else {
        this.Ids = this.Ids + "," + element.id.toString();
      }
    }
    else {
      this.Ids = this.Ids.replace(element.id.toString() + ',', '');
    }
  }
  hideloader() {
    const spinner = Array.from(document.getElementsByClassName('spinner-border') as HTMLCollectionOf<HTMLElement>)
    spinner.forEach((element) => {
      element.style.display = 'none';
    });
  }
 
  showLoader() {
    const spinner = Array.from(document.getElementsByClassName('spinner-border') as HTMLCollectionOf<HTMLElement>)
    spinner.forEach((element) => {
      element.style.display = 'block';
    });
  }

  markAsEligible() {

    if (this.validateDates(this.startDate, this.endDate) && this.startDate != null && this.endDate != null) {
      this.noticeDetails = <NoticeDetails>({
        SelectedInfractions: this.Ids,
        Stage: this.selectedValue,
        TenantName: this.selectedTenant,
        StartDate: this.startDate,
        EndDate: this.endDate
      });
    }
    else if (this.Ids.length > 0 && this.selectedValue.length > 0 && this.isValidDate) {
      this.noticeDetails = <NoticeDetails>({
        SelectedInfractions: this.Ids,
        Stage: this.selectedValue,
        TenantName: this.selectedTenant,
        StartDate: '',
        EndDate: ''
      });

      this.Ids.split(',').forEach(item => {
        let index: number = this.dataSource.data.findIndex(d => d.id === parseInt(item));
        this.dataSource.data.splice(index, 1)
        this.dataSource = new MatTableDataSource<Element>(this.dataSource.data);
        this.dataSource.paginator = this.paginator;
      });
      this.Ids = "";
    }
    else if (this.isValidDate === false) {
      return;
    }


    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(this.noticeDetails);

    this.http.post<NoticeDetails>(this.baseUrl + 'api/Notice/MarkAsEligible', body, { 'headers': headers }).subscribe(data => {
      this.showMsg = true;

      setTimeout(() => {
        this.showMsg = false;
      }, 5000);

    })

  }
  tenantSelectChangeEvent(selectedTenant) {
    this.selectedValue = "";
    this.showGrid = false;
    this.showNoticeDrp = true;
    this.selectedTenant = selectedTenant;
  }
  noticeSelectChangeEvent(event) {
    this.showGrid = false;
    this.bindGrid(event.value);
  }
  bindGrid(selectedStage) {
    this.showLoader();
    this.http.get<Violation[]>(this.baseUrl + 'api/Notice?tenantName=' + this.selectedTenant + '&stage=' + selectedStage + '').subscribe(result => {
      this.dataSource = new MatTableDataSource<Violation>();
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
      this.showGrid = true;
      this.hideloader();
    }, error => console.error(error));
  }

  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {
      this.error = { isError: true, errorMessage: 'End date should be grater then start date.' };
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  openDialog(action, obj) {
    obj.action = action;
    obj.selectedTenant = this.selectedTenant;
    const dialogRef = this.dialog.open(AddViolationDialogComponent, {
      width: '600px',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.action == 'Add') {
        this.addViolation(result.data);
      }
    });
  }
  openPaymentDialog(action, obj) {
    obj.action = action;

    const dialogRef = this.dialog.open(PaymentDialougeComponent, {
      width: '600px',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.action == 'Add') {
        this.addPaymentInfo(result.data);
      }
    });
  }
  addViolation(data) {

    if (data.noOfSelectedTickets === undefined) {
      data.noOfSelectedTickets = 0
    }

    this.violationDetails = <ViolationDetails>({
      SelectedStates: data.selectedState,
      noOfSelectedTickets: data.noOfSelectedTickets,
      ticketCSVString: data.ticketCSVString,
      TenantName: this.selectedTenant
    });

    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(this.violationDetails);

    this.http.post<ApiResult>(this.baseUrl + 'api/Parking/AddNew', body, { 'headers': headers }).subscribe(data => {
      this.showViolationMsg = true;
      this.showViolationMsgText = data.message;

      setTimeout(() => {                          
        this.showViolationMsg = false;
      }, 5000);

      this.selectedValue = "NIC";
      this.bindGrid(this.selectedValue);
    })
  }
  addPaymentInfo(data) {

    this.addPaymentData = data;
    this.addPaymentData.TenantName = this.selectedTenant;
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(this.addPaymentData);

    this.http.post<ApiResult>(this.baseUrl + 'api/Parking/AddPayments', body, { 'headers': headers }).subscribe(data => {
      this.showViolationMsg = true;
      this.showViolationMsgText = "Payment Added for  #" + data.message + "Successfully";
    })
  }
  public ngOnDestroy(): void {
    console.log("Movenotice :: ngOnDestroy");
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from '../event-emitter.service';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit, OnDestroy {
  isExpanded = false;
  showTenants = false;
  collapse() {
    this.isExpanded = false;
  }
  public selectedTenant: string;
  public tenantList: DropdownModel[];
  constructor(public http: HttpClient, @Inject('BASE_URL') public baseUrl: string, private router: Router, private eventEmitterService: EventEmitterService ) { }
  ngOnInit() {
    this.http.get<DropdownModel[]>(this.baseUrl + 'api/Notice/GetTenants').subscribe(result => {
      this.tenantList = result;
    }, error => console.error(error));

    this.showTenants = localStorage.getItem("IsUserAuthenticated") != null;

    //if (this.eventEmitterService.subsLoginVar == undefined) {
    //  this.eventEmitterService.subsLoginVar = this.eventEmitterService.
    //    broadCastLogInSuccess.subscribe(() => {
    //      this.showTenants = true;
    //    });
    //}
      if (this.eventEmitterService.subsLogOutVar == undefined) {
        this.eventEmitterService.subsLogOutVar = this.eventEmitterService.
          broadCastLogOut.subscribe(() => {
            this.showTenants = false;
          });
      }
  }
  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  tenantSelectChangeEvent(event) {
    this.eventEmitterService.onTenantSelectedChangeEvent(event.value);
  }
  logoutUser() {
    localStorage.clear();
    this.selectedTenant = "";
    this.eventEmitterService.onLogOut();
    this.router.navigate(['login']);
  }
  public ngOnDestroy(): void {
    console.log("nav menu :: ngOnDestroy");
  }
}

export interface DropdownModel {
  text: string;
  value: string;
}

import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_LOCALE, MatRadioButton } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownModel, Violation } from '../violation.component';
export interface AddViolationData {
  selectedState: string;
  noOfSelectedTickets: number;
  ticketCSVString: string;
  selectedCType: string;
  selectedTenant: string;
  action: string;
}

@Component({
  selector: 'app-add-violation-dialog',
  templateUrl: './add-violation-dialog.component.html',
  styleUrls: ['./add-violation-dialog.component.css']
})

export class AddViolationDialogComponent {
  action: string;
  selectedTicketCreationType: any;
  ticketCreationOptions = [];
  local_data: AddViolationData;
  public addViolationFormGroup: FormGroup;
  constructor(public dialogRef: MatDialogRef<AddViolationDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: AddViolationData, public http: HttpClient, @Inject('BASE_URL') public baseUrl) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }
  public states: DropdownModel[];

  public nofTickets: DropdownModel[] = [
    { text: '1', value: '1' },
    { text: '5', value: '5' },
    { text: '10', value: '10' }
  ];

  ngOnInit(): void {

    this.addViolationFormGroup = new FormGroup({
      tagState: new FormControl('', [Validators.required]),
      nofTicket: new FormControl(),
      selectedCreationType: new FormControl(['Random Tickets'], [Validators.required]),
      staticTicket: new FormControl()
    });
    this.ticketCreationOptions = ['Random Ticket', 'Specify Ticket'];

    this.setCustomValidation();

    this.http.get<DropdownModel[]>(this.baseUrl + 'api/Notice/GetStates?tenantName=' + this.local_data.selectedTenant + '').subscribe(result => {
      this.states = result;
    }, error => console.error(error));
  }

  setCustomValidation() {

    const staticTicketControl = this.addViolationFormGroup.get('staticTicket');
    const noOfTicketControl = this.addViolationFormGroup.get('nofTicket');

    this.addViolationFormGroup.get('selectedCreationType').valueChanges
      .subscribe(selectedTicketCreationType => {

        if (selectedTicketCreationType === 'Specify Ticket') {
          staticTicketControl.setValidators([Validators.required]);
          noOfTicketControl.setValue(null);
          noOfTicketControl.setValidators(null);
        }
        else if (selectedTicketCreationType === 'Random Ticket') {
          noOfTicketControl.setValidators([Validators.required]);
          staticTicketControl.setValue('');
          staticTicketControl.setValidators(null);
        }
        staticTicketControl.updateValueAndValidity();
        noOfTicketControl.updateValueAndValidity();
      });

  }

  public checkError = (controlName: string, errorName: string) => {
    return this.addViolationFormGroup.controls[controlName].hasError(errorName);
  }
  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }
  closeDialog() {
    this.local_data.action = 'Cancel';
    this.dialogRef.close({ event: 'Cancel', data: this.local_data });
  }
  onTicketNoChange(noofTicket: number) {
    if (noofTicket > 50) {
      this.local_data.noOfSelectedTickets = 1;
    }
  }
  onItemChange(item: any) {
    this.selectedTicketCreationType = item
  }
}
